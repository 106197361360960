import React from 'react';
import type { GatsbyBrowser } from 'gatsby';

import { SiteWrap } from './src/components/SiteWrap';

// Fonts
import '@fontsource/raleway';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/400-italic.css';
import '@fontsource/raleway/700-italic.css';
import '@fontsource/merriweather/300.css';
import '@fontsource/dancing-script';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <SiteWrap {...props}>{element}</SiteWrap>;
};
