import React from 'react';

// CSS globals
import { CSSReset, GlobalStyles } from '@/styles/globalStyles';

// context provider
import ColorModeContextProvider from '@/styles/colorModeContext';

type Props = {
  children: React.ReactNode;
};

/**
 * Main wrapper for the site
 *
 * Wraps the site in the colorMode context provider and injects the CSS reset and global styles
 */
export const SiteWrap: React.FC<Props> = ({ children, ...rest }) => (
  <ColorModeContextProvider {...rest}>
    <CSSReset />
    <GlobalStyles />
    {children}
  </ColorModeContextProvider>
);
