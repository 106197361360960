import * as styled from 'styled-components';

export const CSSReset = styled.createGlobalStyle`
  html {
    box-sizing: border-box;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-text-size-adjust: 100%;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  body {
    line-height: 1.5;
    min-height: 100vh;
    font-family: sans-serif;
  }

  img,
  picture,
  video {
    max-width: 100%;
    height: auto;
    display: block;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
  }

  img {
    border: none;
  }

  a {
    background-color: transparent;
  }

  input,
  button,
  textarea,
  select,
  optgroup {
    font: inherit;
    color: inherit;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  button {
    background-color: transparent;
    background-image: none;
    border: none;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  input {
    overflow: visible;
  }

  label {
    display: inline-block;
  }

  select {
    word-wrap: normal;
  }

  table {
    border-collapse: collapse;
    text-indent: 0;
    border-color: inherit;
  }

  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  details {
    display: block;
  }

  output {
    display: inline-block;
  }

  progress {
    vertical-align: baseline;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      'Liberation Mono', 'Courier New', monospace;
  }

  fieldset {
    min-width: 0;
    border: none;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    font: inherit;
    color: inherit;
    white-space: normal;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:not(:disabled),
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled),
  [role='button'] {
    cursor: pointer;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: none;
    outline-offset: -2px;
  }

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  [hidden] {
    display: none !important;
  }

  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;

export const GlobalStyles = styled.createGlobalStyle`
  :root {
    ${'' /* dark: (temporary, to keep track of colors) */}
    ${
      '' /* --clr-bg-1: #101423;
    --clr-bg-2: #1F2947;
    --clr-bg-header: #101423:
    --clr-bg-footer: #101423;
    --clr-body: #F4F6FA;
    --clr-body-light: #F4F6FA;
    --clr-primary: #A7B2D8;
    --clr-primary-hover: #A7B2D8;
    --clr-muted: #6C757D; 
    --clr-secondary: #ffba9f; */
    }


    ${'' /* light (overrides) */}
    --clr-bg-1: #FFFFFF;
    --clr-bg-2: #f4f6fa;
    --clr-bg-3: #dbe0f0;
    --clr-bg-header: #596fb5;
    --clr-bg-footer: #1f2947;
    --clr-body: #101423;
    --clr-body-light: #ffffff;
    --clr-primary: #596fb5;
    --clr-primary-hover: #445797;
    --clr-muted: #6b6b6b;
    --clr-secondary: #ffba9f;

    font-size: 100%;
    line-height: 1.5;
    overflow-wrap: break-word;
  }

  body {
    color: var(--clr-body);
    background-color: var(--clr-bg-2);
    transition: all 0.2s;
    font-family: 'Raleway', sans-serif;
    font-size: 1.125rem;
    letter-spacing: 0.05rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  ul:not([role='list']),
  ol:not([role='list']) {
    margin-left: 2rem;
    margin-bottom: 1rem;
    & li {
      margin-bottom: 0rem;
    }
  }

  iframe {
    border: none;
  }

  a:focus,
  button:focus {
    outline: none;
  }

  blockquote {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
    border-left: 3px solid var(--clr-primary);
    transition: all 0.3s;
    font-size: 1.125rem;
    &.landing-quote {
      border: none;
    }
    & > p {
      margin-bottom: 0.5rem;
    }
    @media (min-width: 768px) {
      padding-left: 3rem;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
    ${'' /* color: inherit; */}

    &:hover {
      text-decoration: none;
    }
  }

  .wp-block-post-content a:focus {
    outline: 1px dotted var(--clr-body);
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  .no-scroll {
    overflow: hidden;
  }

  .no-scroll::before {
    content: '';
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(16, 20, 35, 0.7);
    z-index: 8;
    cursor: pointer;
  }

  iframe {
    max-width: 100%;
  }
`;
