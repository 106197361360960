// if updating the palette, make sure to also update colors in InjectInitialColorMode
export const colors = {
  'bg-1': {
    light: '#FFFFFF',
    dark: '#101423',
  },
  'bg-2': {
    light: '#f4f6fa',
    dark: '#1F2947',
  },
  'bg-3': {
    light: '#DBE0F0',
    dark: '#1F2947',
  },
  'bg-header': {
    light: '#596fb5',
    dark: '#101423',
  },
  'bg-footer': {
    light: '#1f2947',
    dark: '#101423',
  },
  body: {
    light: '#101423',
    dark: '#F4F6FA',
  },
  'body-light': {
    light: '#ffffff',
    dark: '#F4F6FA',
  },
  primary: {
    light: '#596fb5',
    dark: '#A7B2D8',
  },
  'primary-hover': {
    light: '#445797',
    dark: '#A7B2D8',
  },
  muted: {
    light: '#6B6B6B',
    dark: '#9DA4AA',
  },
  secondary: {
    light: '#ffba9f',
    dark: '#ffba9f',
  },
};
