import React from 'react';

import { useColorMode } from './useColorMode';
import type { ColorMode } from './useColorMode';

type ColorModeContextType = {
  colorMode: ColorMode;
  toggleColorMode: (colorMode?: ColorMode) => void;
};

const defaultContext = {
  colorMode: undefined,
  toggleColorMode: () => null,
};

type Props = {
  initial?: ColorMode;
  children: React.ReactNode;
};

export const ColorModeContext =
  React.createContext<ColorModeContextType>(defaultContext);
/**
 * Provider for the site's context.
 * Current, the context value consists of:
 * - theme - a string representing the site's theme ('dark' or 'light')
 * - toggleTheme - a function that takes no arguments, and toggles between the theme's possible values.
 */
const ColorModeContextProvider: React.FC<Props> = ({ initial, children }) => {
  const [colorMode, toggleColorMode] = useColorMode(initial);
  return (
    <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
      {children}
    </ColorModeContext.Provider>
  );
};

export default ColorModeContextProvider;
