import { useState, useEffect } from 'react';
import { generatePalette } from './generatePalette';

export type ColorMode = 'light' | 'dark' | undefined;

export const useColorMode = (initial?: ColorMode) => {
  const [colorMode, setColorMode] = useState<ColorMode>(initial || undefined);

  useEffect(() => {
    const root = window.document.documentElement;
    const initialColorValue = root.dataset.theme;
    if (initialColorValue === 'light' || initialColorValue === 'dark') {
      setColorMode(initialColorValue);
    }
  }, []);

  const setMode = (mode: 'light' | 'dark') => {
    const root = window.document.documentElement;
    root.dataset.theme = mode;
    localStorage.setItem('colorMode', mode);
    setColorMode(mode);
    generatePalette(mode);
  };

  /**
   * Toggles the colorMode.
   */
  const toggleColorMode = (newColorMode?: 'light' | 'dark') => {
    if (newColorMode === 'light' || newColorMode === 'dark')
      setMode(newColorMode);
    else colorMode === 'light' ? setMode('dark') : setMode('light');
  };

  return [colorMode, toggleColorMode] as [
    ColorMode,
    (colorMode?: ColorMode) => void
  ];
};
