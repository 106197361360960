import { colors } from './palette';

type Color = [string, { light: string; dark: string }];

/**
 * Constructs a color palette based on the given colorMode value (defaults light).
 */
export const generatePalette = (colorMode: 'light' | 'dark' = 'light') => {
  Object.entries(colors).forEach(([name, getColorByMode]: Color) => {
    const customPropName = `--clr-${name}`;
    try {
      document.documentElement.style.setProperty(
        customPropName,
        getColorByMode[colorMode]
      );
    } catch (error) {
      console.warn(
        'There was a problem defining the CSS custom properties for the color mode theme.'
      );
    }
  });
};
